import Footer from '#app/components/pageLayout/Footer.tsx'
import Header from '#app/components/pageLayout/Header.tsx'
import LogoCloud from '#app/components/pageLayout/LogoCloud.tsx'
import { Outlet } from '@remix-run/react'

export default function Mk() {
	return (
		<>
			<div className="flex min-h-screen flex-col">
				<Header />
				<div className="container flex-1 ">
					<Outlet />
				</div>
			</div>
			<LogoCloud />
			<Footer />
		</>
	)
}
